import actionCreator from '../../actioncreators/accountLinking';
import meActionCreator from '../../actioncreators/me';
import { LiteralText, PrimaryButton, LinkButton, ConfirmModal } from '../../components';
import namespaceTranslatedText from '../TranslatedText';
import UtilityHelper from '../../utils/helpers';
import { AddIcon } from '../../components/elements/icons';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TranslatedText = namespaceTranslatedText('ACCOUNT_LINKING');

class UnwrappedAccountLinking extends Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    personId: PropTypes.string,
    companyName: PropTypes.string.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    isModalShown: PropTypes.bool.isRequired,

    dispatchResetState: PropTypes.func.isRequired,
    dispatchLoadAndVerifyToken: PropTypes.func.isRequired,
    dispatchLogout: PropTypes.func.isRequired,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchHideModal: PropTypes.func.isRequired,
    dispatchConnect: PropTypes.func.isRequired,
    dispatchDeclineInvitation: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    personId: undefined,
  };

  constructor(props) {
    super(props);

    const queryParamMap = UtilityHelper.parseQueryString(props.location.search);

    this.token = queryParamMap.token;
  }

  componentDidMount() {
    const { dispatchLoadAndVerifyToken } = this.props;

    dispatchLoadAndVerifyToken(this.token);
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  onNotYou = () => {
    const { dispatchLogout } = this.props;

    dispatchLogout(`/auth/create-account/${this.token}`);
  };

  onDeclineModalButtonClick = () => {
    const { dispatchShowModal } = this.props;

    dispatchShowModal();
  };

  handleConnect = () => {
    const { dispatchConnect, personId } = this.props;

    dispatchConnect(personId, this.token);
  };

  handleDeclineModalConfirm = () => {
    const { companyName, dispatchDeclineInvitation } = this.props;

    dispatchDeclineInvitation(companyName, this.token);
  };

  handleDeclineModalCancel = () => {
    const { dispatchHideModal } = this.props;

    dispatchHideModal();
  };

  renderModal() {
    const { isModalShown, companyName } = this.props;

    if (!isModalShown) {
      return null;
    }
    return (
      <ConfirmModal
        data-meta-id="confirm-decline-modal"
        title={<TranslatedText textKey="DECLINE_INVITATION" />}
        onConfirm={this.handleDeclineModalConfirm}
        onCancel={this.handleDeclineModalCancel}
        confirmText={<TranslatedText textKey="DECLINE_INVITATION" />}
        cancelText={<TranslatedText textKey="GO_BACK" />}
      >
        <TranslatedText textKey="DECLINE_MESSAGE" params={{ companyName }} />
      </ConfirmModal>
    );
  }

  render() {
    const { isLoadingData, companyName, firstName, lastName } = this.props;

    if (isLoadingData) {
      return null;
    }

    return (
      <section className="account-linking">
        <div className="greeting-row">
          <TranslatedText textKey="GREETING" params={{ firstName }} />
        </div>
        <h3>
          <TranslatedText textKey="JOINING" params={{ companyName }} />
        </h3>
        <div className="images-row">
          <div>
            <div className="avatar initials">
              <LiteralText>
                {firstName.charAt(0)}
                {lastName.charAt(0)}
              </LiteralText>
            </div>
            <div className="display-text">
              <LiteralText>
                {firstName} {lastName}
              </LiteralText>
            </div>
          </div>
          <AddIcon />
          <div>
            <div className="avatar initials company rectangular">
              <LiteralText>
                {companyName
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase())
                  .join('')}
              </LiteralText>
            </div>
            <div className="display-text">
              <LiteralText>{companyName}</LiteralText>
            </div>
          </div>
        </div>
        <div className="instructions-row">
          <TranslatedText textKey="CONNECT_TO_COMPANY" params={{ companyName }} />
        </div>
        <div className="connect-button">
          <PrimaryButton
            onClick={this.handleConnect}
            className="input-large"
            data-meta-id="connect-button"
          >
            <TranslatedText textKey="CONNECT_BUTTON" />
          </PrimaryButton>
        </div>
        <div>
          <TranslatedText textKey="TAKE_OTHER_ACTION" />
        </div>
        <div className="actions-row">
          <LinkButton onClick={this.onNotYou} data-meta-id="not-you-link">
            <TranslatedText textKey="I_AM_NOT" params={{ firstName, lastName }} />
          </LinkButton>
          <LinkButton
            onClick={this.onDeclineModalButtonClick}
            data-meta-id="decline-invitation-link"
          >
            <TranslatedText textKey="DECLINE_THIS_INVITATION" params={{ firstName, lastName }} />
          </LinkButton>
        </div>
        {this.renderModal()}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.me.main.firstName,
    lastName: state.me.main.lastName,
    personId: state.me.main.personId,
    companyName: state.accountLinking.main.companyName,
    isLoadingData: state.accountLinking.loadData.isLoadingData,
    isModalShown: state.accountLinking.main.isModalShown,
    dictionary: state.translation.main.dictionary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchLoadAndVerifyToken(token) {
      dispatch(actionCreator.loadAndVerifyToken(token));
    },

    dispatchLogout(redirectLocation) {
      dispatch(meActionCreator.logout(redirectLocation));
    },

    dispatchShowModal() {
      dispatch(actionCreator.showModal());
    },

    dispatchHideModal() {
      dispatch(actionCreator.hideModal());
    },

    dispatchConnect(personId, token) {
      dispatch(actionCreator.connect(personId, token));
    },

    dispatchDeclineInvitation(companyName, token) {
      dispatch(actionCreator.declineInvitation(companyName, token));
    },
  };
}

const AccountLinking = connect(mapStateToProps, mapDispatchToProps)(UnwrappedAccountLinking);

export { UnwrappedAccountLinking, AccountLinking as default };
