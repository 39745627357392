import { TitledIcon, ButtonContainer, LiteralText } from 'components/';
import namespaceTranslatedText from 'containers/TranslatedText/';
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TranslatedText = namespaceTranslatedText('DECLINE_CONFIRMATION');

function DeclineConfirmation(props) {
  const { location, companyName: propCompanyName } = props;
  const companyName = (location.state && location.state.companyName) || propCompanyName;

  return (
    <section className="decline-confirmation">
      <header className="decline-header">
        <div className="avatar initials company rectangular">
          <LiteralText>
            {companyName
              .split(' ')
              .map((word) => word.charAt(0))
              .join()}
          </LiteralText>
        </div>
        <TitledIcon type="decline" className="decline-icon" />
      </header>
      <main>
        <div className="headline">
          <TranslatedText textKey="SUCCESSFUL_MESSAGE" params={{ companyName }} />
        </div>
        <div className="description">{/* TBD */}</div>
      </main>
      <footer>
        <ButtonContainer>
          <a href="/" className="back-link">
            <TranslatedText textKey="BACK_TO_HOME" />
          </a>
        </ButtonContainer>
      </footer>
    </section>
  );
}

DeclineConfirmation.propTypes = {
  companyName: PropTypes.string,

  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

DeclineConfirmation.defaultProps = {
  companyName: '',
};

export { DeclineConfirmation as default };
