import translationUtils from './utility';
import Parser from 'html-react-parser';

class Translator {
  constructor(namespace, dictionary = {}) {
    this.namespace = namespace;
    this.dictionary = dictionary;
  }

  translateKey(dictionary, fullKey) {
    const useEvaltaBranding = window.location.hostname.includes('evalta');
    const translatedText = dictionary[fullKey];

    if (useEvaltaBranding && translatedText) {
      return translatedText.replace('Lifion', 'Evalta');
    }
    return translatedText;
  }

  getFullKey(key) {
    const namespace = !this.namespace ? '' : `${this.namespace}.`;

    return `${namespace}${key}`;
  }

  getStringUsingInterpolationStrategy(key, dictionary, params, interpolationStrategy) {
    const fallbackTranslatedText = !key ? `${key}` : `**${key}**`; //this is to fail i18n tests when key does not exist
    const chosenDictionary = dictionary || this.dictionary;

    try {
      const fullKey = this.getFullKey(key);
      const translatedText = this.translateKey(chosenDictionary, fullKey);

      if (!translatedText) {
        return fallbackTranslatedText;
      }

      if (params) {
        return interpolationStrategy(translatedText, params);
      }

      return translatedText;
    } catch (e) {
      return fallbackTranslatedText;
    }
  }

  getReactRenderedString(key, dictionary, params) {
    return this.getStringUsingInterpolationStrategy(
      key,
      dictionary,
      params,
      translationUtils.interpolateWithReactObjects
    );
  }

  get(key, dictionary, params) {
    return this.getStringUsingInterpolationStrategy(
      key,
      dictionary,
      params,
      translationUtils.interpolate
    );
  }

  getHtml(key, dictionary, params) {
    const chosenDictionary = dictionary || this.dictionary;

    return Parser(this.get(key, chosenDictionary, params));
  }
}

export { Translator as default };
