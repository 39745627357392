import actionCreator from '../../actioncreators/legalAgreements';
import { withNamespace } from '../../components/elements/textDisplays/TranslatedText';

import UtilityHelper from '../../utils/helpers';
import { Card, Checkbox, InlineMessage, PrimaryButton, ButtonContainer } from '../../components';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { Header2 } from '@idm/ui-components';
import { Form } from '@tbiegner99/react-forms';

const TranslatedText = withNamespace('LEGAL_AGREEMENTS');

class LegalAgreements extends Component {
  static propTypes = {
    agreements: PropTypes.arrayOf(
      PropTypes.shape({
        agreementName: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired,
        documentUrl: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    isInitializing: PropTypes.bool.isRequired,

    onLoad: PropTypes.func.isRequired,
    onFormSubmission: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    const queryParamMap = UtilityHelper.parseQueryString(props.location.search);

    this.encodedKeys = UtilityHelper.encodeBase64(queryParamMap.agreements || '');

    this.state = {
      shouldShowErrorMsg: false,
      isAgreementsAccepted: false,
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;

    onLoad(this.encodedKeys);
  }

  submitAgreemeent = () => {
    const { agreements, onFormSubmission } = this.props;

    const agreementsData = {
      legalAgreements: agreements.map((agreement) => ({
        agreementName: agreement.agreementName,
        version: agreement.version,
      })),
    };

    onFormSubmission(agreementsData);
  };

  renderAgreements() {
    const { agreements } = this.props;

    if (agreements.length === 0) {
      return null;
    }

    const cards = agreements.map((agreement) => (
      <Card
        className="agreement-card"
        key={agreement.key}
        mainText={agreement.displayName}
        subText={UtilityHelper.parseDateForDisplay(agreement.createdAt, 'MM/DD/YYYY')}
        actionText={<TranslatedText i18nKey="READ_BUTTON" />}
        onActionClick={() => window.open(agreement.documentUrl)}
        showAvatar={false}
      />
    ));

    return <div className="auth-servers">{cards}</div>;
  }

  render() {
    const { isInitializing } = this.props;
    const { shouldShowErrorMsg, isAgreementsAccepted } = this.state;

    if (isInitializing) {
      return null;
    }

    return (
      <Form onSubmit={() => this.submitAgreemeent()} className="legal-agreements">
        <Header2>
          <TranslatedText i18nKey="HEADER" />
        </Header2>

        {this.renderAgreements()}
        <Checkbox
          onChange={(isChecked) =>
            this.setState({ isAgreementsAccepted: isChecked, shouldShowErrorMsg: !isChecked })
          }
          data-msg-required=""
          selected={isAgreementsAccepted}
          data-rule-required={true}
        >
          <TranslatedText i18nKey="ACKNOWLEDGEMENT" />
        </Checkbox>
        {shouldShowErrorMsg && (
          <InlineMessage type="error">
            <span data-meta-id="error-message">
              <TranslatedText i18nKey="ERROR_MESSAGE" />
            </span>
          </InlineMessage>
        )}
        <ButtonContainer>
          <PrimaryButton
            onClick={() => this.setState({ shouldShowErrorMsg: !isAgreementsAccepted })}
            submittable={true}
            size="large"
            className="agree-button"
            data-meta-id="agree-button"
          >
            <TranslatedText i18nKey="AGREE_BUTTON" />
          </PrimaryButton>
        </ButtonContainer>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    agreements: state.legalAgreements.main.agreements.map((agreement) => ({
      ...agreement,
      key: `${agreement.agreementName}${agreement.version}`,
    })),
    isInitializing: state.me.main.isCheckingAuth || state.legalAgreements.loadData.isLoadingData,
  };
}

function mapDispatchToProps() {
  return {
    onLoad(keys) {
      return actionCreator.loadData(keys);
    },

    onFormSubmission(agreementsData) {
      return actionCreator.agreeToLegalAgreements(agreementsData);
    },
  };
}

export { LegalAgreements as UnwrappedLegalAgreements };
export default connect(mapStateToProps, mapDispatchToProps)(LegalAgreements);
