import logoPath from '../../../static/assets/images/Logo@2x.png';
import chairPath from '../../../static/assets/images/chair-with-spill.png';
import namespaceTranslatedText from 'containers/TranslatedText/';
import UtilityHelper from 'utils/helpers/';
import { Flexbox } from '@idm/ui-components';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TranslatedText = namespaceTranslatedText('OUTAGE_PAGE');

function OutagePage(props) {
  const { location } = props;

  useEffect(() => {
    const queryParams = UtilityHelper.parseQueryString(location.search);
    const error = queryParams.error || '';

    /* eslint-disable no-console */
    if (error) console.log(`SAML Error: ${error}`);
  }, [location]);

  return (
    <div className="outage-page-container">
      <Flexbox className="outage-page-layout">
        <Flexbox className="message-container">
          <img alt="Logo" className="lifion-logo" src={logoPath} />
          <h1 className="xlarge-page-header">
            <TranslatedText textKey="THATS_NOT_RIGHT" />
          </h1>
          <span className="message">
            <TranslatedText textKey="OUTAGE_MESSAGE" />
          </span>
          <span className="message">
            <TranslatedText textKey="MORE_INFORMATION_MESSAGE" containHtml={true} />
          </span>
        </Flexbox>
        <Flexbox className="chair-with-spill">
          <img alt="Chair with spill" src={chairPath} />
        </Flexbox>
      </Flexbox>
    </div>
  );
}

OutagePage.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export { OutagePage as default };
