import KnowledgeCheck from './KnowledgeCheck';
import actionCreator from '../../actioncreators/accountRegistration';
import applicationActionCreator from '../../actioncreators/application';
import Translator from '../../utils/translation/Translator';
import UtilityHelper from '../../utils/helpers';
import { Card, LiteralText } from '../../components';
import namespaceTranslatedText from '../TranslatedText';
import { RightArrow } from '../../components/elements/icons';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TranslatedText = namespaceTranslatedText('ACCOUNT_REGISTRATION');
const accountRegistrationDictionary = new Translator('ACCOUNT_REGISTRATION');

class AccountRegistration extends Component {
  static propTypes = {
    companyName: PropTypes.string.isRequired,
    associateFirstName: PropTypes.string.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    skipToCreateAccount: PropTypes.bool.isRequired,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchResetState: PropTypes.func.isRequired,
    dispatchLoadAndVerifyToken: PropTypes.func.isRequired,
    dispatchGetLocale: PropTypes.func.isRequired,
    onChangeUrl: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    isValidDob: PropTypes.bool.isRequired,
    showDobRequired: PropTypes.bool.isRequired,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    const queryParamMap = UtilityHelper.parseQueryString(props.location.search);

    this.token = queryParamMap.token;
  }

  componentDidMount() {
    const { dispatchLoadAndVerifyToken, dispatchGetLocale } = this.props;

    dispatchLoadAndVerifyToken(this.token);
    dispatchGetLocale();
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  handleAlreadyHaveAccountClick = () => {
    const { onChangeUrl } = this.props;

    onChangeUrl(`/auth/login?redirectUrl=%2Fauth%2Fuser-confirmation%3Ftoken%3D${this.token}`);
  };

  handleSkipToCreateAccount = () => {
    this.handleNewToLifionClick();
  };

  handleNewToLifionClick = () => {
    const { onChangeUrl } = this.props;

    onChangeUrl(`/auth/create-account/${this.token}`);
  };

  renderKnowledgeCheck() {
    const { location, locale } = this.props;

    return <KnowledgeCheck location={location} locale={locale} />;
  }

  renderRegistration() {
    const { associateFirstName, companyName, dictionary, isLoadingData } = this.props;

    if (isLoadingData) {
      return null;
    }

    const companyNameInitials = companyName
      .split(' ')
      .map((word) => word.charAt(0))
      .join('');
    const hiddenStyle = {
      display: 'none',
    };

    return (
      <section className="account-registration">
        <div className="greeting-row">
          <div style={hiddenStyle} className="company-logo avatar initials company rectangular">
            <LiteralText>{companyNameInitials}</LiteralText>
          </div>
          <h1>
            <TranslatedText
              textKey="WELCOME_MESSAGE"
              params={{
                associateFirstName: associateFirstName ? `, ${associateFirstName}` : '',
                companyName: ` ${companyName}`,
              }}
            />
          </h1>
        </div>
        <Card
          className="option-card"
          mainText={accountRegistrationDictionary.get('ALREADY_HAVE_ACCOUNT_TITLE', dictionary)}
          subText={accountRegistrationDictionary.get('ALREADY_HAVE_ACCOUNT_MESSAGE', dictionary, {
            companyName,
          })}
          actionText={<RightArrow width={15} height={24} />}
          onWholeCardClick={this.handleAlreadyHaveAccountClick}
          showAvatar={false}
        />
        <div className="text-center">
          <TranslatedText textKey="OR" />
        </div>
        <Card
          className="option-card"
          mainText={accountRegistrationDictionary.get('NEW_TO_LIFION_TITLE', dictionary)}
          subText={accountRegistrationDictionary.get('NEW_TO_LIFION_MESSAGE', dictionary)}
          actionText={<RightArrow width={15} height={24} />}
          onWholeCardClick={this.handleNewToLifionClick}
          showAvatar={false}
        />
      </section>
    );
  }

  render() {
    const { showDobRequired, isValidDob, skipToCreateAccount } = this.props;

    if (showDobRequired && !isValidDob) {
      return this.renderKnowledgeCheck();
    }

    if (skipToCreateAccount) {
      this.handleSkipToCreateAccount();
      return null;
    }

    return this.renderRegistration();
  }
}

function mapStateToProps(state) {
  return {
    companyName: state.accountRegistration.main.companyName,
    associateFirstName: state.accountRegistration.main.associateFirstName,
    isLoadingData: state.accountRegistration.loadData.isLoadingData,
    skipToCreateAccount: state.accountRegistration.main.skipToCreateAccount,
    dictionary: state.translation.main.dictionary,
    associateId: state.accountRegistration.main.associateId,
    clientId: state.accountRegistration.main.clientId,
    showDobRequired: state.knowledgeCheck.showDobRequired,
    isValidDob: state.knowledgeCheck.isValidDob,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeUrl(url) {
      applicationActionCreator.changeUrl(url);
    },
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchLoadAndVerifyToken(token) {
      dispatch(actionCreator.loadAndVerifyToken(token));
    },

    dispatchGetLocale() {
      dispatch(actionCreator.getLocale());
    },
  };
}

export { AccountRegistration as UnwrappedAccountRegistration };
export default connect(mapStateToProps, mapDispatchToProps)(AccountRegistration);
