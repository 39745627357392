class WindowUtils {
  static getWindowLocation() {
    return window.location;
  }

  static getCurrentPath() {
    return WindowUtils.getWindowLocation().pathname;
  }

  static refresh() {
    WindowUtils.getWindowLocation().reload();
  }

  static getNexoBaseUrl() {
    return window.__nexo_base_url__;
  }
}

export default WindowUtils;
