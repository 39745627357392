import styles from './index.module.css';
import TranslatedText from '../../../../components/elements/textDisplays/TranslatedText';

import Translator from '../../../../utils/translation/Translator';
import TranslationContext from '../../../../context/TranslationsContext';
import { SmallParagraph, SecondaryButton, SdfSelectPicklist } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

const itemShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}).isRequired;

class LocalizationSettingsForm extends React.Component {
  static contextType = TranslationContext;

  static propTypes = {
    languages: PropTypes.arrayOf(itemShape).isRequired,
    regions: PropTypes.arrayOf(itemShape).isRequired,
    timezones: PropTypes.arrayOf(itemShape).isRequired,

    onSubmit: PropTypes.func.isRequired,

    defaultRegion: PropTypes.string,
    defaultLanguage: PropTypes.string,
    defaultTimezone: PropTypes.string,
  };

  static defaultProps = {
    defaultRegion: null,
    defaultLanguage: null,
    defaultTimezone: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      language: props.defaultLanguage,
      region: props.defaultRegion,
      timezone: props.defaultTimezone,
    };
  }

  onLanguageChange(value) {
    this.setState({
      language: value,
    });
  }

  onTimezoneChange(value) {
    this.setState({
      timezone: value,
    });
  }

  onRegionChange(value) {
    this.setState({
      region: value,
    });
  }

  updateRegionalSettings() {
    const { language, region, timezone } = this.state;
    const { onSubmit } = this.props;

    onSubmit({
      language,
      region,
      timezone,
    });
  }

  render() {
    const { languages, regions, timezones } = this.props;
    const { language, region, timezone } = this.state;
    const { dictionary } = this.context;
    const translator = new Translator('', dictionary);
    const languageLabel = translator.getReactRenderedString(
      'ACCOUNT_SETTINGS.LOCALIZATION.LANGUAGE',
      null
    );
    const regionLabel = translator.getReactRenderedString(
      'ACCOUNT_SETTINGS.LOCALIZATION.REGION',
      null
    );
    const timezoneLabel = translator.getReactRenderedString(
      'ACCOUNT_SETTINGS.LOCALIZATION.TIMEZONE',
      null
    );
    const emptyOption = translator.getReactRenderedString('DROPDOWN.NOT_FOUND', null);

    const disableLanguage = !languages;
    const disableRegion = !regions;
    const disableTimezone = !timezones;

    return (
      <div className={styles.settingsForm}>
        <section className={styles.formContent}>
          <div className={styles.dropdown}>
            <SdfSelectPicklist
              label={languageLabel}
              onChange={(value) => this.onLanguageChange(value)}
              value={language}
              dataMetaId="language-select"
              placeholder={translator.get('DROPDOWN.PLACEHOLDER')}
              emptyOption={emptyOption}
              options={languages}
              disabled={disableLanguage}
            />
            <SmallParagraph className={styles.regionText}>
              <TranslatedText i18nKey="ACCOUNT_SETTINGS.LOCALIZATION.SELECT_TIMEZONE" />
            </SmallParagraph>
          </div>
          <div className={styles.dropdown}>
            <SdfSelectPicklist
              label={regionLabel}
              onChange={(value) => this.onRegionChange(value)}
              value={region}
              dataMetaId="region-select"
              placeholder={translator.get('DROPDOWN.PLACEHOLDER')}
              emptyOption={emptyOption}
              options={regions}
              disabled={disableRegion}
            />
          </div>

          <div className={styles.dropdown}>
            <SdfSelectPicklist
              label={timezoneLabel}
              onChange={(value) => this.onTimezoneChange(value)}
              value={timezone}
              dataMetaId="timezone-select"
              placeholder={translator.get('DROPDOWN.PLACEHOLDER')}
              emptyOption={emptyOption}
              options={timezones}
              disabled={disableTimezone}
            />
          </div>
        </section>
        <div className={styles.buttonContainer}>
          <SecondaryButton
            onClick={() => this.updateRegionalSettings()}
            data-meta-id="save-change-button"
          >
            <TranslatedText i18nKey="ACCOUNT_SETTINGS.LOCALIZATION.SAVE_CHANGES" />
          </SecondaryButton>
        </div>
      </div>
    );
  }
}

export default LocalizationSettingsForm;
