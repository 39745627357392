import serializer from './serializer';
import HttpClient from '../../../utils/http/HttpClient';
import getConstants from '../../../utils/constants';
import cookieUtils from '../../../utils/cookie';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;
const cultureInfoUrl = constants.serviceBaseUrls.cultureInfo;

class LocalizationDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });

    this.httpClientCC = HttpClient.createInstance({
      baseURL: cultureInfoUrl,
    });
  }

  async getLanguages() {
    const { data: response } = await this.httpClient.get('/persons/me/settings-options/languages');

    return serializer.getLanguages.fromResponse(response);
  }

  async getRegions() {
    const cookieLanguage = cookieUtils.getCookie('language');
    const { data: response } = await this.httpClient.get('/persons/me/settings-options/regions', {
      headers: {
        'x-ohcm-user-language': cookieLanguage,
      },
    });

    return serializer.getRegions.fromResponse(response);
  }

  async getTimezones() {
    const cookieLanguage = cookieUtils.getCookie('language');
    const cookieRegion = cookieUtils.getCookie('region');

    const { data: response } = await this.httpClient.get('/persons/me/settings-options/timezones', {
      headers: {
        'x-ohcm-user-language': cookieLanguage,
        'user-region': cookieRegion,
      },
    });

    return serializer.getTimezones.fromResponse(response);
  }

  async getMyLocaleSettings() {
    const { data: response } = await this.httpClient.get('/persons/me/settings');

    return serializer.settings.fromGetSettingsResponse(response);
  }

  async setMyLocaleSettings(settings) {
    const request = serializer.settings.toUpdateSettingsRequest(settings);

    await this.httpClient.post('/persons/me/settings', request);
  }
}
export default new LocalizationDatasource();
