import WindowUtils from '../../utils/helpers/window';
import UtilityHelper from '../../utils/helpers';
import applicationActionCreator from '../application';
import buildLoadDataActionCreator from 'actioncreators/builders/loadData';
import loginactionCreator from 'actioncreators/login';

import actionTypes, { actionTypeSections } from 'actiontypes/';

const switchClientsRedirectPageActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT),

  setSwitchClientsDetails(username, isNexoClient, externalBaseUrl) {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT.SET_USER_CLIENT_DETAILS,
      payload: {
        username,
        isNexoClient,
        externalBaseUrl,
      },
    };
  },

  handleClientSwitchRedirect(isNexoClient, externalBaseUrl) {
    return async (dispatch) => {
      if (isNexoClient) {
        const redirectUrl = UtilityHelper.getRedirectUrl(WindowUtils.getNexoBaseUrl());

        applicationActionCreator.changeUrl(redirectUrl);
      } else {
        await dispatch(loginactionCreator.redirectToExternalBaseUrl(externalBaseUrl));
      }
    };
  },

  resetMainState() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT.RESET_STATE,
    };
  },
};

export { switchClientsRedirectPageActionCreator as default };
