import styles from './style.module.scss';
import UtilityHelper from '../../utils/helpers';
import { Notification, LinkButton } from 'components/';
import namespaceTranslatedText from 'containers/TranslatedText/';
import getConstants, { staticConstants } from 'utils/constants';
import urls from 'utils/urls';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

const TranslatedText = namespaceTranslatedText('UNAUTHENTICATED_NOTIFICATION');
const { requestResult } = getConstants();
const { mobileEventKeys } = staticConstants;

class UnauthenticatedNotification extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    registrationCode: PropTypes.string,
  };

  static defaultProps = {
    registrationCode: '',
  };

  static getRedirectLink() {
    const queryParams = UtilityHelper.parseQueryString(window.location.search);
    const redirectUrl = queryParams.redirectUrl || queryParams.returnUrl || '';

    return UtilityHelper.convertToRelativePath(redirectUrl);
  }

  getNetsecureSelfRegistrationLink = () => {
    const redirectUrl = window.__netsecure_self_registration_url__;

    const link = (
      <a
        href={`${redirectUrl}/pages/sms/ess/v3/pub/ssr/theme.jsp`}
        className="redirect-link"
        data-meta-id="redirect-link"
      >
        <TranslatedText textKey="FAILURE_REDIRECT_TO_AIM_REDIRECT" />
      </a>
    );

    return link;
  };

  notificationProps = {
    [requestResult.FAILURE_LINK]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_LINK_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_LINK_MESSAGE" />
        </p>
      ),
      children: (
        <Link to="/auth/login">
          <TranslatedText textKey="BACK_TO_LIFION" />
        </Link>
      ),
    },
    [requestResult.FAILURE_LINK_VERIFY_EMAIL]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_LINK_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_LINK_VERIFY_EMAIL_MESSAGE" />
        </p>
      ),
      children: (
        <Link to="/auth/login">
          <TranslatedText textKey="BACK_TO_LIFION" />
        </Link>
      ),
    },
    [requestResult.FAILURE_LINK_RESET_PASS]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_LINK_VERIFY_EMAIL_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_LINK_RESET_PASS_MESSAGE" />
        </p>
      ),
      children: (
        <Link to="/auth/forgot-password/request">
          <TranslatedText textKey="FAILURE_LINK_RESET_PASS_TRY_AGAIN" />
        </Link>
      ),
    },
    [requestResult.FAILURE_UNAUTHORIZED]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_UNAUTHORIZED_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_UNAUTHORIZED_MESSAGE" containHtml={true} />
        </p>
      ),
      children: (
        <Link to="/auth/login">
          <TranslatedText textKey="BACK_TO_LIFION" />
        </Link>
      ),
    },
    [requestResult.FAILURE_RECOVER_ATTEMPT]: {
      status: 'info',
      title: <TranslatedText textKey="FAILURE_RECOVER_ATTEMPT_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_RECOVER_ATTEMPT_MESSAGE" containHtml={true} />
        </p>
      ),
      children: (
        <Link to="/auth/login">
          <TranslatedText textKey="BACK_TO_LIFION" />
        </Link>
      ),
    },
    [requestResult.FAILURE_UNSUPPORTED]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_UNSUPPORTED_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_UNSUPPORTED_MESSAGE" containHtml={true} />
        </p>
      ),
      children: (
        <Link to="/auth/login">
          <TranslatedText textKey="BACK_TO_LIFION" />
        </Link>
      ),
    },
    [requestResult.FAILURE_INCOMPLETE_PROFILE]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_INCOMPLETE_PROFILE_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_INCOMPLETE_PROFILE_MESSAGE" containHtml={true} />
        </p>
      ),
      children: (
        <Link to="/auth/login">
          <TranslatedText textKey="BACK_TO_LIFION" />
        </Link>
      ),
    },
    [requestResult.FAILURE_REDIRECT_TO_AIM]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_REDIRECT_TO_AIM_TITLE" />,
      message: (
        <p>
          <TranslatedText
            textKey="FAILURE_REDIRECT_TO_AIM_MESSAGE"
            containHtml={true}
            params={{ pic: this.props.registrationCode }}
          />
        </p>
      ),
      children: this.getNetsecureSelfRegistrationLink(),
    },
    [requestResult.FAILURE_VERIFY_CONTACT_INFO_GENERIC_ERROR]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_UNSUPPORTED_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="FAILURE_UNSUPPORTED_MESSAGE" containHtml={true} />
        </p>
      ),
      children: UtilityHelper.isReactNativeWebView() ? (
        <LinkButton
          data-meta-id="continue-link"
          className={styles.continueLink}
          onClick={() => UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_ERROR)}
        >
          <TranslatedText textKey="CONTINUE" />
        </LinkButton>
      ) : (
        <a data-meta-id="continue-link" href={UnauthenticatedNotification.getRedirectLink()}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },
    [requestResult.FAILURE_VERIFY_CONTACT_INFO_TOO_MANY_ATTEMPTS]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_VERIFY_CONTACT_INFO_TOO_MANY_ATTEMPTS_TITLE" />,
      message: (
        <p>
          <TranslatedText
            textKey="FAILURE_VERIFY_CONTACT_INFO_TOO_MANY_ATTEMPTS_MESSAGE"
            containHtml={true}
          />
        </p>
      ),
      children: UtilityHelper.isReactNativeWebView() ? (
        <LinkButton
          data-meta-id="continue-link"
          className={styles.continueLink}
          onClick={() => UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_ERROR)}
        >
          <TranslatedText textKey="CONTINUE" />
        </LinkButton>
      ) : (
        <a data-meta-id="continue-link" href={UnauthenticatedNotification.getRedirectLink()}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },
    [requestResult.FAILURE_VERIFY_CONTACT_INFO_DUPLICATE_EMAIL]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_VERIFY_CONTACT_INFO_DUPLICATE_EMAIL_TITLE" />,
      message: (
        <p>
          <TranslatedText
            textKey="FAILURE_VERIFY_CONTACT_INFO_DUPLICATE_EMAIL_MESSAGE"
            containHtml={true}
          />
        </p>
      ),
      children: UtilityHelper.isReactNativeWebView() ? (
        <LinkButton
          data-meta-id="continue-link"
          className={styles.continueLink}
          onClick={() => UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_ERROR)}
        >
          <TranslatedText textKey="CONTINUE" />
        </LinkButton>
      ) : (
        <a data-meta-id="continue-link" href={UnauthenticatedNotification.getRedirectLink()}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },
    [requestResult.FAILURE_VERIFY_CONTACT_INFO_INVALID_FORMAT]: {
      status: 'error',
      title: <TranslatedText textKey="FAILURE_VERIFY_CONTACT_INFO_INVALID_FORMAT_TITLE" />,
      message: (
        <p>
          <TranslatedText
            textKey="FAILURE_VERIFY_CONTACT_INFO_INVALID_FORMAT_MESSAGE"
            containHtml={true}
          />
        </p>
      ),
      children: UtilityHelper.isReactNativeWebView() ? (
        <LinkButton
          data-meta-id="continue-link"
          className={styles.continueLink}
          onClick={() => UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_ERROR)}
        >
          <TranslatedText textKey="CONTINUE" />
        </LinkButton>
      ) : (
        <a data-meta-id="continue-link" href={UnauthenticatedNotification.getRedirectLink()}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },

    [requestResult.SUCCESS_VERIFY_EMAIL]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_VERIFY_EMAIL_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_VERIFY_EMAIL_MESSAGE" />
        </p>
      ),
      'data-meta-id': 'verification-success',
      children: UtilityHelper.isMobile() ? (
        <TranslatedText textKey="CONFIRM_ON_MOBILE" style={{ fontSize: '14px' }} />
      ) : (
        <Link to="/auth/login">
          <TranslatedText textKey="CONTINUE" />
        </Link>
      ),
    },
    [requestResult.SUCCESS_FORGOT_PASS_REQUEST]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_FORGOT_PASS_REQUEST_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_FORGOT_PASS_REQUEST_MESSAGE" containHtml={true} />
        </p>
      ),
    },
    [requestResult.SUCCESS_FORGOT_USERNAME_REQUEST]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_FORGOT_USERNAME_REQUEST_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_FORGOT_USERNAME_REQUEST_MESSAGE" containHtml={true} />
        </p>
      ),
    },
    [requestResult.SUCCESS_SELF_REGISTRATION_REQUEST]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_SELF_REGISTRATION_REQUEST_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_SELF_REGISTRATION_REQUEST_MESSAGE" containHtml={true} />
        </p>
      ),
    },
    [requestResult.SUCCESS_FORGOT_PASS_RESET]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_FORGOT_PASS_RESET_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_FORGOT_PASS_RESET_MESSAGE" />
        </p>
      ),
      children: (
        <Link to="/auth/login">
          <TranslatedText textKey="CONTINUE" />
        </Link>
      ),
    },
    [requestResult.SUCCESS_CREATE_ACCOUNT]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_CREATE_ACCOUNT_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_CREATE_ACCOUNT_MESSAGE" />
        </p>
      ),
      children: (
        <a href={urls.LANDING_PAGE}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },
    [requestResult.SUCCESS_ACCOUNT_ALREADY_CREATED]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_ACCOUNT_ALREADY_CREATED_TITLE" />,
      message: (
        <p>
          <TranslatedText
            textKey="SUCCESS_ACCOUNT_ALREADY_CREATED_MESSAGE"
            params={{
              link: UnauthenticatedNotification.getRedirectLink(),
            }}
            containHtml={true}
          />
        </p>
      ),
    },
    [requestResult.SUCCESS_VERIFY_CONTACT_INFO_EMAIL]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_VERIFY_CONTACT_INFO_EMAIL_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_VERIFY_CONTACT_INFO_EMAIL_MESSAGE" />
        </p>
      ),
      children: UtilityHelper.isReactNativeWebView() ? (
        <LinkButton
          data-meta-id="continue-link"
          className={styles.continueLink}
          onClick={() =>
            UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_SUCCESS)
          }
        >
          <TranslatedText textKey="CONTINUE" />
        </LinkButton>
      ) : (
        <a data-meta-id="continue-link" href={UnauthenticatedNotification.getRedirectLink()}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },
    [requestResult.SUCCESS_VERIFY_CONTACT_INFO_PHONE]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_VERIFY_CONTACT_INFO_PHONE_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_VERIFY_CONTACT_INFO_PHONE_MESSAGE" />
        </p>
      ),
      children: UtilityHelper.isReactNativeWebView() ? (
        <LinkButton
          data-meta-id="continue-link"
          className={styles.continueLink}
          onClick={() =>
            UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_SUCCESS)
          }
        >
          <TranslatedText textKey="CONTINUE" />
        </LinkButton>
      ) : (
        <a data-meta-id="continue-link" href={UnauthenticatedNotification.getRedirectLink()}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },
    [requestResult.SUCCESS_VERIFY_CONTACT_INFO_ALREADY_VERIFIED]: {
      status: 'success',
      title: <TranslatedText textKey="SUCCESS_VERIFY_CONTACT_INFO_ALREADY_VERIFIED_TITLE" />,
      message: (
        <p>
          <TranslatedText textKey="SUCCESS_VERIFY_CONTACT_INFO_ALREADY_VERIFIED_MESSAGE" />
        </p>
      ),
      children: UtilityHelper.isReactNativeWebView() ? (
        <LinkButton
          data-meta-id="continue-link"
          className={styles.continueLink}
          onClick={() =>
            UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_SUCCESS)
          }
        >
          <TranslatedText textKey="CONTINUE" />
        </LinkButton>
      ) : (
        <a data-meta-id="continue-link" href={UnauthenticatedNotification.getRedirectLink()}>
          <TranslatedText textKey="CONTINUE" />
        </a>
      ),
    },
  };

  render() {
    const { location } = this.props;
    const notificationProps =
      this.notificationProps[location.state.REDIRECT_RESULT] ||
      this.notificationProps.FAILURE_UNSUPPORTED;

    return <Notification {...notificationProps} />;
  }
}

function mapStateToProps(state) {
  return {
    registrationCode: state.accountRegistration.main.registrationCode,
  };
}

export { UnauthenticatedNotification as UnwrappedUnauthenticatedNotification };
export default compose(withRouter, connect(mapStateToProps))(UnauthenticatedNotification);
