import actionCreator from '../../../actioncreators/createAccount/personalInfo';
import Translator from '../../../utils/translation/Translator';
import {
  TextInput,
  EmailInput,
  ButtonContainer,
  InlineMessage,
  CheckboxInput,
  PrimaryButton,
  LinkButton,
} from '../../../components';
import namespaceTranslatedText from '../../TranslatedText';
import { NoDuplicateRule, NoNonAdpEmailRule } from '../../../utils/validator/rules';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TranslatedText = namespaceTranslatedText('CREATE_ACCOUNT.PERSONAL_INFO');
const personalInfoDictionary = new Translator('CREATE_ACCOUNT.PERSONAL_INFO');

class UnwrappedPersonalInfo extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    isSSO: PropTypes.bool.isRequired,
    formTermsAccepted: PropTypes.bool.isRequired,
    formFirstName: PropTypes.string.isRequired,
    formLastName: PropTypes.string.isRequired,
    formEmail: PropTypes.string.isRequired,
    isValidating: PropTypes.bool.isRequired,
    isAllValid: PropTypes.bool.isRequired,
    companyName: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSetFormIsValidating: PropTypes.func.isRequired,
    dispatchSetFormFieldValidationResult: PropTypes.func.isRequired,
    dispatchSubmitForm: PropTypes.func.isRequired,
    dispatchSendResetPasswordEmail: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showTermsError: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { isValidating } = this.props;

    if (this.shouldSubmitForm(isValidating, nextProps.isValidating, nextProps.isAllValid)) {
      this.submitForm();
    }
  }

  getAdditionalEmailRules() {
    const { token, isSSO, dictionary } = this.props;
    const associateToken = {
      token,
      kind: isSSO ? 'associateSSO' : 'associate',
    };

    const customErrorMessageComponent = (
      <>
        <span>{personalInfoDictionary.get('DUP_EMAIL_ERROR_MESSAGE_FRAGMENT_1', dictionary)}</span>
        <LinkButton className="click-here-link" onClick={this.sendResetPasswordEmail}>
          {personalInfoDictionary.get('DUP_EMAIL_ERROR_MESSAGE_FRAGMENT_2', dictionary)}
        </LinkButton>
        <span>{personalInfoDictionary.get('DUP_EMAIL_ERROR_MESSAGE_FRAGMENT_3', dictionary)}</span>
      </>
    );

    return [
      new NoDuplicateRule(
        personalInfoDictionary.get('DUP_EMAIL_RULE', dictionary),
        associateToken,
        'emailAddress',
        customErrorMessageComponent
      ),
      new NoNonAdpEmailRule(personalInfoDictionary.get('NO_NON_ADP_EMAIL_RULE', dictionary)),
    ];
  }

  submitForm() {
    const { formTermsAccepted, isSSO, dispatchSubmitForm, formEmail } = this.props;

    if (isSSO && !formTermsAccepted) {
      this.setState({
        showTermsError: true,
      });
    } else {
      dispatchSubmitForm({
        email: formEmail,
      });
    }
  }

  shouldSubmitForm = (isFormSubmitted, isStillValidating, isAllValid) =>
    isFormSubmitted && !isStillValidating && isAllValid;

  handleChange(field, event) {
    const { dispatchSetFormField } = this.props;

    dispatchSetFormField(field, event.target.value);
  }

  handleValidationChange(field, validationResult) {
    const { dispatchSetFormFieldValidationResult } = this.props;

    dispatchSetFormFieldValidationResult(field, validationResult);
  }

  handleFirstNameChange = this.handleChange.bind(this, 'firstName');

  handleLastNameChange = this.handleChange.bind(this, 'lastName');

  handleEmailChange = this.handleChange.bind(this, 'email');

  handleFirstNameValidationChange = this.handleValidationChange.bind(this, 'firstName');

  handleLastNameValidationChange = this.handleValidationChange.bind(this, 'lastName');

  handleEmailValidationChange = this.handleValidationChange.bind(this, 'email');

  handleKeyPress = (event) => {
    const { dispatchSetFormIsValidating } = this.props;

    if (event.key === 'Enter') {
      dispatchSetFormIsValidating();
    }
  };

  handleTermsChanged = (isChecked) => {
    const { dispatchSetFormField } = this.props;

    this.setState({ showTermsError: !isChecked }, () => {
      dispatchSetFormField('termsAccepted', isChecked);
    });
  };

  renderTermsAndConditions() {
    const { isSSO, formTermsAccepted } = this.props;
    const { showTermsError } = this.state;

    if (!isSSO) {
      return null;
    }
    const checkboxInputLabel = (
      <span>
        <TranslatedText textKey="ACKNOWLEDGEMENT" containHtml={true} />
      </span>
    );

    return (
      <div data-meta-id="terms-checkbox">
        <CheckboxInput
          onChange={this.handleTermsChanged}
          value={formTermsAccepted}
          label={checkboxInputLabel}
          id="agreement-checkbox"
        />
        {showTermsError && (
          <InlineMessage type="error">
            <span data-meta-id="error-message">
              <TranslatedText textKey="ERROR_MESSAGE" />
            </span>
          </InlineMessage>
        )}
      </div>
    );
  }

  renderAccountLinkingLink() {
    const { isSSO, token } = this.props;
    const loginUrlWithAccountLinkingRedirect = `/auth/login?redirectUrl=%2Fauth%2Faccount-linking%3Ftoken%3D${token}`;

    if (isSSO) {
      return null;
    }

    return (
      <div className="account-exists-link button-set">
        <TranslatedText textKey="ALREADY_HAVE_ACCOUNT" />{' '}
        <Link to={loginUrlWithAccountLinkingRedirect}>
          <TranslatedText textKey="LOG_IN_LINK" />
        </Link>
      </div>
    );
  }

  renderNameInputs() {
    const { formFirstName, formLastName, isValidating, dictionary, isSSO } = this.props;

    if (isSSO) {
      return null;
    }

    return (
      <div data-meta-id="name-inputs">
        <TextInput
          forceValidationCheck={isValidating}
          handleValidationChange={this.handleFirstNameValidationChange}
          onChange={this.handleFirstNameChange}
          onKeyPress={this.handleKeyPress}
          value={formFirstName}
          label={personalInfoDictionary.get('FIRST_NAME_LABEL', dictionary)}
          errorMessage={personalInfoDictionary.get('FIRST_NAME_ERROR_MSG', dictionary)}
          type="text"
          className="input-large"
          autoFocus={true}
          id="first-name-input"
          dataMetaId="first-name-input"
        />
        <TextInput
          forceValidationCheck={isValidating}
          handleValidationChange={this.handleLastNameValidationChange}
          onChange={this.handleLastNameChange}
          onKeyPress={this.handleKeyPress}
          value={formLastName}
          label={personalInfoDictionary.get('LAST_NAME_LABEL', dictionary)}
          errorMessage={personalInfoDictionary.get('LAST_NAME_ERROR_MSG', dictionary)}
          type="text"
          className="input-large"
          id="last-name-input"
          dataMetaId="last-name-input"
        />
      </div>
    );
  }

  sendResetPasswordEmail = () => {
    const { formEmail } = this.props;

    this.props.dispatchSendResetPasswordEmail(formEmail);
  };

  renderEmailInputField() {
    const { formEmail, isValidating, dictionary, clientId } = this.props;

    return (
      <>
        <EmailInput
          forceValidationCheck={isValidating}
          additionalRules={this.getAdditionalEmailRules()}
          handleValidationChange={this.handleEmailValidationChange}
          onChange={this.handleEmailChange}
          onKeyPress={this.handleKeyPress}
          value={formEmail}
          label={personalInfoDictionary.get('PERSONAL_EMAIL', dictionary)}
          className="input-large idm-email-wrapper"
          id="email-input"
          dataMetaId="email-input"
          clientId={clientId}
        />
      </>
    );
  }

  render() {
    const { isSSO, firstName, companyName, dispatchSetFormIsValidating } = this.props;

    return (
      <section className="personal-info">
        <div className="greeting-row">
          <h1>
            <TranslatedText
              textKey="WELCOME_MESSAGE"
              params={{
                associateFirstName: firstName ? `, ${firstName}` : '',
                companyName: ` ${companyName}`,
              }}
            />
          </h1>
        </div>
        <p>
          <TranslatedText textKey={`${isSSO ? 'SSO.' : ''}PROVIDE_INFO_MESSAGE`} />
        </p>
        {this.renderAccountLinkingLink()}

        {this.renderNameInputs()}

        {this.renderEmailInputField()}

        <InlineMessage>
          <TranslatedText textKey="PERSONAL_EMAIL_MESSAGE" />
        </InlineMessage>
        {this.renderTermsAndConditions()}
        <ButtonContainer>
          <PrimaryButton
            onClick={dispatchSetFormIsValidating}
            size="large"
            data-meta-id="next-button"
          >
            <TranslatedText textKey="NEXT_BUTTON" />
          </PrimaryButton>
        </ButtonContainer>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let validatedFields = ['firstName', 'lastName', 'email'];

  if (ownProps.isSSO) {
    validatedFields = ['email'];
  }

  return {
    formFirstName: state.createAccountPersonalInfo.form.firstName.value,
    formLastName: state.createAccountPersonalInfo.form.lastName.value,
    formEmail: state.createAccountPersonalInfo.form.email.value,
    formTermsAccepted: state.createAccountPersonalInfo.form.termsAccepted.value,
    isAllValid: validatedFields.every((key) => state.createAccountPersonalInfo.form[key].isValid),
    isValidating: validatedFields.some(
      (key) => state.createAccountPersonalInfo.form[key].isValidating
    ),
    companyName: state.createAccountPersonalInfo.main.companyName,
    clientId: state.createAccountPersonalInfo.main.clientId,
    isInitializing:
      state.createAccountPersonalInfo.loadData.isLoadingData ||
      state.createAccountPersonalInfo.main.isCheckingAuth,
    dictionary: state.translation.main.dictionary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchSetFormIsValidating() {
      dispatch(actionCreator.setFormIsValidating());
    },

    dispatchSetFormFieldValidationResult(field, validationResult) {
      dispatch(actionCreator.setFormFieldValidationResult(field, validationResult));
    },

    dispatchSendResetPasswordEmail(email) {
      dispatch(actionCreator.sendResetPasswordEmail(email));
    },
  };
}

const PersonalInfo = connect(mapStateToProps, mapDispatchToProps)(UnwrappedPersonalInfo);

export { UnwrappedPersonalInfo, PersonalInfo as default };
