import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  username: '',
  isNexoClient: false,
  externalBaseUrl: '',
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  switch (action.type) {
    case actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT.SET_USER_CLIENT_DETAILS:
      return {
        ...state,
        username: action.payload.username,
        isNexoClient: action.payload.isNexoClient,
        externalBaseUrl: action.payload.externalBaseUrl,
      };
    case actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT.RESET_STATE:
      return { ...state };
    default:
      return state;
  }
}

const switchClientsRedirectPageReducer = combineReducers({
  loadData: buildLoadDataReducer(
    loadDataInitialState,
    actionTypeSections.ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT
  ),
  main: mainReducer,
});

export { switchClientsRedirectPageReducer as default };
