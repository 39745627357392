function getConstants(baseUrl = '') {
  return {
    serviceBaseUrls: {
      self: `${baseUrl}/api/identity-ui-server/v0`,
      uiWebService: `${baseUrl}/api/ui-web-api-gateway/v1`,
      authenticationService: `${baseUrl}/api/authentication-service/v0`,
      identityOrchestrator: `${baseUrl}/api/identity-orchestrator/v0`,
      identityOrchestratorV1: `${baseUrl}/api/identity-orchestrator/v1`,
      cultureInfo: `${baseUrl}/api/culture-info/v1`,
      globalizationStaticsService: `${baseUrl}/api/globalization-statics-service/v1`,
    },

    tiles: {
      dashboard: 'd2cc1d0753144173b6e79671608cf2b8',
      forgotCredentials: 'e10a129cb24a46f4b7f51565484efdff',
    },

    pages: {
      outage: 'http://outage.flowbrite.com/',
    },

    defaultRegionalSettings: {
      language: 'en-US',
      region: 'US',
      timezone:
        (Intl &&
          Intl.DateTimeFormat() &&
          Intl.DateTimeFormat().resolvedOptions() &&
          Intl.DateTimeFormat().resolvedOptions().timeZone) ||
        'America/New_York',
    },

    requestResult: {
      FAILURE: 'FAILURE',
      FAILURE_LINK: 'FAILURE_LINK',
      FAILURE_LINK_VERIFY_EMAIL: 'FAILURE_LINK_VERIFY_EMAIL',
      FAILURE_LINK_RESET_PASS: 'FAILURE_LINK_RESET_PASS',
      FAILURE_SAML_INCORRECT_APP_ID: 'FAILURE_SAML_INCORRECT_APP_ID',
      FAILURE_UNAUTHORIZED: 'FAILURE_UNAUTHORIZED',
      FAILURE_RECOVER_ATTEMPT: 'FAILURE_RECOVER_ATTEMPT',
      FAILURE_REDIRECT_TO_AIM: 'FAILURE_REDIRECT_TO_AIM',
      FAILURE_UNSUPPORTED: 'FAILURE_UNSUPPORTED',
      FAILURE_INCOMPLETE_PROFILE: 'FAILURE_INCOMPLETE_PROFILE',
      FAILURE_SSO_LOGIN_REQUEST: 'FAILURE_SSO_LOGIN_REQUEST',
      FAILURE_UNDETERMINED_NEXO_SESSION: 'FAILURE_UNDETERMINED_NEXO_SESSION',
      FAILURE_VERIFY_CONTACT_INFO_GENERIC_ERROR: 'FAILURE_VERIFY_CONTACT_INFO_GENERIC_ERROR',
      FAILURE_VERIFY_CONTACT_INFO_TOO_MANY_ATTEMPTS:
        'FAILURE_VERIFY_CONTACT_INFO_TOO_MANY_ATTEMPTS',
      FAILURE_VERIFY_CONTACT_INFO_DUPLICATE_EMAIL: 'FAILURE_VERIFY_CONTACT_INFO_DUPLICATE_EMAIL',
      FAILURE_VERIFY_CONTACT_INFO_INVALID_FORMAT: 'FAILURE_VERIFY_CONTACT_INFO_INVALID_FORMAT',

      SUCCESS: 'SUCCESS',
      SUCCESS_VERIFY_EMAIL: 'SUCCESS_VERIFY_EMAIL',
      SUCCESS_FORGOT_PASS_REQUEST: 'SUCCESS_FORGOT_PASS_REQUEST',
      SUCCESS_FORGOT_USERNAME_REQUEST: 'SUCCESS_FORGOT_USERNAME_REQUEST',
      SUCCESS_SELF_REGISTRATION_REQUEST: 'SUCCESS_SELF_REGISTRATION_REQUEST',
      SUCCESS_FORGOT_PASS_RESET: 'SUCCESS_FORGOT_PASS_RESET',
      SUCCESS_CREATE_ACCOUNT: 'SUCCESS_CREATE_ACCOUNT',
      SUCCESS_ACCOUNT_ALREADY_CREATED: 'SUCCESS_ACCOUNT_ALREADY_CREATED',
      SUCCESS_SSO_LOGIN_REQUEST: 'SUCCESS_SSO_LOGIN_REQUEST',
      SUCCESS_VERIFY_CONTACT_INFO_EMAIL: 'SUCCESS_VERIFY_CONTACT_INFO_EMAIL',
      SUCCESS_VERIFY_CONTACT_INFO_PHONE: 'SUCCESS_VERIFY_CONTACT_INFO_PHONE',
      SUCCESS_VERIFY_CONTACT_INFO_ALREADY_VERIFIED: 'SUCCESS_VERIFY_CONTACT_INFO_ALREADY_VERIFIED',
    },

    gracePeriodInMS: 5000,
    ONE_YEAR: 365 * 24 * 60 * 60 * 1000,

    defaultLanguage: 'en',

    ADP_COOKIE_DOMAIN: '.adp.com',
    ADP_LANGUAGE_COOKIE: 'ADPLangLocaleCookie',

    supportedLanguages: {
      legalPolicy: [
        'nl-NL',
        'en-CA',
        'es-ES',
        'de-DE',
        'es-US',
        'fr-CA',
        'fr-FR',
        'hu-HU',
        'it-IT',
        'ja-JP',
        'ko-KR',
        'pl-PL',
        'pt-BR',
        'ru-RU',
        'sk-SK',
        'zh-CN',
        'zh-TW',
      ],
    },

    authenticationMethod: {
      NEXO: 'Nexo',
      LIFION: 'Lifion',
    },
  };
}

const noop = () => {};
const ONE_SEC = 1000;
const THREE_SEC = 3 * ONE_SEC;
const ONE_MIN = 60000;
const serviceBaseUrls = {
  self: '/api/identity-ui-server/v0',
  uiWebService: '/api/ui-web-api-gateway/v1',
  authenticationService: '/api/authentication-service/v0',
  identityOrchestrator: '/api/identity-orchestrator/v0',
  cultureInfo: '/api/culture-info/v1',
  globalizationStaticsService: '/api/globalization-statics-service/v1',
};
const errorTypes = {
  FAILURE: 'FAILURE',
  FAILURE_LINK: 'FAILURE_LINK',
  FAILURE_LINK_VERIFY_EMAIL: 'FAILURE_LINK_VERIFY_EMAIL',
  FAILURE_LINK_RESET_PASS: 'FAILURE_LINK_RESET_PASS',
  FAILURE_SAML_INCORRECT_APP_ID: 'FAILURE_SAML_INCORRECT_APP_ID',
  FAILURE_UNAUTHORIZED: 'FAILURE_UNAUTHORIZED',
  FAILURE_RECOVER_ATTEMPT: 'FAILURE_RECOVER_ATTEMPT',
  FAILURE_UNSUPPORTED: 'FAILURE_UNSUPPORTED',
  BAD_REGISTRATION_TOKEN: 'BAD_REGISTRATION_TOKEN',

  SUCCESS: 'SUCCESS',
  SUCCESS_VERIFY_EMAIL: 'SUCCESS_VERIFY_EMAIL',
  SUCCESS_FORGOT_PASS_REQUEST: 'SUCCESS_FORGOT_PASS_REQUEST',
  SUCCESS_FORGOT_PASS_RESET: 'SUCCESS_FORGOT_PASS_RESET',
  SUCCESS_FORGOT_USERNAME_REQUEST: 'SUCCESS_FORGOT_USERNAME_REQUEST',
  SUCCESS_CREATE_ACCOUNT: 'SUCCESS_CREATE_ACCOUNT',
  SUCCESS_ACCOUNT_ALREADY_CREATED: 'SUCCESS_ACCOUNT_ALREADY_CREATED',
};

const tokenKinds = {
  ASSOCIATE: 'associate',
  ASSOCIATE_SSO: 'associateSSO',
  REGISTRATION_EMAIL_VERIFICATION: 'codeEmailVerification',
  CREATE_CREDENTIAL: 'credentialCreation',
};

const staticConstants = {
  statusCodes: {
    FORBIDDEN: 403,
    OK: 200,
    NO_CONTENT: 204,
  },
  NOOP: noop,
  ONE_SEC: 1000,
  ONE_MIN: 60000,
  DEFAULT_LOGIN_LANDING_PAGE: '/api/authentication-service/v0/landing',
  UNAUTHORIZED_ERROR_PAGE: '/auth/unauthorized-error',
  tokenKinds: {
    ASSOCIATE: 'associate',
    ASSOCIATE_SSO: 'associateSSO',
    REGISTRATION_EMAIL_VERIFICATION: 'codeEmailVerification',
  },
  toastMessage: {
    SUCCESS_TOAST_TYPE: 'success',
    INFO_TOAST_TYPE: 'info',
    FAILURE_TOAST_TYPE: 'error',
    TOAST_I18N_NAMESPACE: 'TOAST_MESSAGE',
  },
  obligationActionTypes: {
    REDIRECT: 'redirect',
    COMMAND: 'command',
  },
  obligationCommandKinds: {
    CREATE_BASIC_CREDENTIALS: 'createBasicCredentials',
    REQUIRE_SAML: 'forceSamlAuthentication',
    REQUIRE_BASIC_CREDENTIALS: 'forceBasicCredentialAuthentication',
  },
  localStorage: {
    IS_CHANGE_PASSWORD_MODAL_DISPLAYING: 'isChangePasswordModalDisplaying',
  },
  featureFlags: {
    REQUIRE_ADP_EMAIL_FOR_ICC_ACCESS: 'requireAdpEmailForICCAccess',
    REQUIRE_ONLY_ALLOW_ADP_EMAIL: 'requireOnlyAdpEmails',
    SYNC_SESSION_TTL_FEATURE: 'syncSessionTTLFeatureEnabled',
  },
  workstreams: {
    IDM: 'idm',
  },
  contactInfoVerification: {
    types: {
      EMAIL: 'email',
      PHONE: 'phone',
    },
    purposes: {
      BUSINESS: 'business',
      PERSONAL: 'personal',
    },
  },
  mobileEventKeys: {
    STEP_UP_SUCCESS: 'STEP_UP_SUCCESS',
    STEP_UP_CANCEL: 'STEP_UP_CANCEL',
    STEP_UP_ERROR: 'STEP_UP_ERROR',
    IDM_CONTACT_VERIFY_SUCCESS: 'IDM_CONTACT_VERIFY_SUCCESS',
    IDM_CONTACT_VERIFY_CANCEL: 'IDM_CONTACT_VERIFY_CANCEL',
    IDM_CONTACT_VERIFY_ERROR: 'IDM_CONTACT_VERIFY_ERROR',
  },
  logLevels: {
    DEBUG: 'debug',
    ERROR: 'error',
    FATAL: 'fatal',
    INFO: 'info',
    TRACE: 'trace',
    WARN: 'warn',
  },
  errorCodes: {
    EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
    PHONE_NUMBER_ALREADY_EXISTS: 'PHONE_NUMBER_ALREADY_EXISTS',
  },
  errorNotificationEvents: {
    DUPE_EMAIL_ERROR: 'DUPE_EMAIL_ERROR',
    DUPE_PHONE_NUMBER_ERROR: 'DUPE_PHONE_NUMBER_ERROR',
    ADD_ERROR: 'ADD_ERROR',
    ADD_PHONE_ERROR: 'ADD_PHONE_ERROR',
    ADD_EMAIL_ERROR: 'ADD_EMAIL_ERROR',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  },
  LEVEL_OF_ASSURANCE_MFA: 3,
  lifionHomePage: '/view/home',
  domains: {
    EXTERNAL_DOMAIN: '.adp.com',
  },
};

const adpAdmin = {
  emailPattern: /(adp\.com$)/i,
};

export {
  getConstants as default,
  noop,
  ONE_SEC,
  ONE_MIN,
  THREE_SEC,
  tokenKinds,
  staticConstants,
  errorTypes,
  serviceBaseUrls,
  adpAdmin,
};
