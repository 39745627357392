import { RegexRule } from '@tbiegner99/react-forms';

const REGEX = /^([a-zA-Z0-9-_]|[.](?=[a-zA-Z0-9-_]|$))+$/;

class UsernameRule extends RegexRule {
  constructor() {
    super(REGEX);
  }

  static get ruleName() {
    return 'username';
  }

  getDefaultMessage() {
    return 'Usernames may only contain numbers,letters,_,-, and non-consecutive periods.';
  }
}

export default UsernameRule;
