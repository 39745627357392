import helpers from '../helpers';
import getConstants from '../constants';
import Cookies from 'universal-cookie';
import { convertLifionLanguageCodeToStandardADPCode } from 'lifion-to-adp-language-mapper';

const { defaultRegionalSettings, ONE_YEAR, ADP_COOKIE_DOMAIN, ADP_LANGUAGE_COOKIE } =
  getConstants();
const cookies = new Cookies();

export default {
  setCookie(key, value, options = {}) {
    const sevenDaysLater = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

    cookies.set(key, value, { path: '/', expires: sevenDaysLater, ...options });
  },

  getCookie(key) {
    return cookies.get(key);
  },

  removeCookieByName(name) {
    document.cookie = `${encodeURIComponent(
      name
    )}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  },

  setRegionalSettingsCookies(regionalSettings) {
    const adpLanguageCode = convertLifionLanguageCodeToStandardADPCode(regionalSettings.language);

    const settings = {
      ...defaultRegionalSettings,
      ...regionalSettings,
    };

    const oneYearLater = new Date(new Date().getTime() + ONE_YEAR);
    const hostname = helpers.getHostnameForCookies();
    const domainDerivedFromHostname = hostname ? `.${hostname}` : null;

    Object.keys(settings).forEach((key) => {
      this.setCookie(key, settings[key], {
        domain: domainDerivedFromHostname,
        expires: oneYearLater,
      });
    });

    this.setCookie(ADP_LANGUAGE_COOKIE, adpLanguageCode, {
      domain: ADP_COOKIE_DOMAIN,
      expires: oneYearLater,
    });
  },
};
