import styles from './styles.module.scss';
import meActionCreator from '../../../actioncreators/me';
import modalActionCreator from '../../../actioncreators/modal';
import applicationActionCreator from '../../../actioncreators/application';
import Translator from '../../../utils/translation/Translator';
import AuthenticatedNotification from '../../AuthenticatedNotification';
import Header from '../../Header';
import UserConfirmation from '../../UserConfirmation';
import AccountLinking from '../../AccountLinking';
import DeclineConfirmation from '../../DeclineConfirmation';
import AccountSettings from '../../AccountSettings';
import ChooseProduct from '../../ChooseProduct';
import ImpersonationModalPage from '../../StartImpersonation';
import Saml from '../../Saml';
import { ButtonContainer, SecondaryButton, PrimaryButton } from '../../../components';
import UtilityHelper from '../../../utils/helpers';
import urls from '../../../utils/urls';
import { noop } from '../../../utils/constants';
import ShadowBoxOverlay from '../../../components/elements/containers/ShadowBoxOverlay';
import { Modal } from '@idm/ui-components';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const authLayoutDictionary = new Translator('LAYOUTS.AUTHENTICATED');

class AuthenticatedLayout extends Component {
  static propTypes = {
    className: PropTypes.string,

    isInitializing: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticatedByNexo: PropTypes.bool.isRequired,
    isPageDirty: PropTypes.bool.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalBody: PropTypes.string.isRequired,
    modalOkButtonText: PropTypes.string.isRequired,
    modalCancelButtonText: PropTypes.string.isRequired,
    modalOkCallback: PropTypes.func.isRequired,
    modalCancelCallback: PropTypes.func.isRequired,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchResetState: PropTypes.func.isRequired,
    dispatchInitialize: PropTypes.func.isRequired,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchHideModal: PropTypes.func.isRequired,
    onChangeUrl: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    className: '',
  };

  componentDidMount() {
    const { location, dictionary, dispatchInitialize } = this.props;

    let redirectLocation = null;
    let redirectImmediately = false;

    if (location.pathname.includes('/auth/account-settings')) {
      const redirectUrl = encodeURIComponent(`${location.pathname}${location.search}`);

      redirectLocation = `/auth/login?redirectUrl=${redirectUrl}`;
    } else if (
      location.pathname.includes('/auth/account-linking') ||
      location.pathname.includes('/auth/user-confirmation')
    ) {
      const { token } = UtilityHelper.parseQueryString(location.search);

      redirectLocation = `/auth/create-account/${token}`;
    } else if (location.pathname.includes('/auth/sso-idp-adp/marketplace')) {
      const { marketplaceApplicationGroupName } = UtilityHelper.parseQueryString(location.search);

      if (
        marketplaceApplicationGroupName &&
        marketplaceApplicationGroupName.match(/^NS_SSO_TESTING/)
      ) {
        const { 1: baseUrl } = marketplaceApplicationGroupName.split('~');

        redirectLocation = `${baseUrl}/api/icc/v0/sso-tested`;
        redirectImmediately = true;
      }
    }
    dispatchInitialize(redirectLocation, redirectImmediately);

    this.stringifiedModalProps = JSON.stringify({
      title: authLayoutDictionary.get('MODAL_TITLE', dictionary),
      body: authLayoutDictionary.get('MODAL_BODY', dictionary),
      okButtonText: authLayoutDictionary.get('MODAL_OK', dictionary),
      cancelButtonText: authLayoutDictionary.get('MODAL_CANCEL', dictionary),
    });
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  onClickBackButton = () => {
    const { onChangeUrl, dispatchShowModal, isPageDirty } = this.props;

    if (isPageDirty) {
      const okCallback = () => onChangeUrl(urls.LANDING_PAGE);
      const cancelCallback = noop;

      dispatchShowModal(this.stringifiedModalProps, okCallback, cancelCallback);

      return;
    }

    onChangeUrl(urls.LANDING_PAGE);
  };

  onClickLogoutButton = () => {
    const { onChangeUrl } = this.props;

    onChangeUrl('/auth/logout');
  };

  onClickModalOkButton = () => {
    const { modalOkCallback, dispatchHideModal } = this.props;

    modalOkCallback();
    dispatchHideModal();
  };

  onClickModalCancelButton = () => {
    const { modalCancelCallback, dispatchHideModal } = this.props;

    modalCancelCallback();
    dispatchHideModal();
  };

  renderHeader() {
    const { location } = this.props;
    const headerProps = { onClickLogoutButton: this.onClickLogoutButton };

    if (location.pathname.includes('/auth/account-settings')) {
      headerProps.showBackButton = true;
      headerProps.onClickBackButton = this.onClickBackButton;
    }

    return <Header {...headerProps} />;
  }

  renderContent() {
    const { isAuthenticated, onChangeUrl, isAuthenticatedByNexo } = this.props;

    if (isAuthenticated) {
      return (
        <Switch>
          <Route exact={true} path="/auth/user-confirmation" component={UserConfirmation} />
          <Route exact={true} path="/auth/account-linking" component={AccountLinking} />
          <Route exact={true} path="/auth/decline-confirmation" component={DeclineConfirmation} />
          <Route exact={true} path="/auth/sso-idp-adp/marketplace" component={Saml} />
          <Route exact={true} path="/auth/choose-product" component={ChooseProduct} />
          <Route exact={true} path="/auth/impersonation" component={ImpersonationModalPage} />
          <Route
            path="/auth/account-settings"
            render={(props) => (
              <AccountSettings
                {...props}
                onChangeUrl={onChangeUrl}
                isAuthenticatedByNexo={isAuthenticatedByNexo}
              />
            )}
          />
        </Switch>
      );
    }
    return null;
  }

  render() {
    const {
      isInitializing,
      className,
      isModalVisible,
      modalTitle,
      modalOkButtonText,
      modalCancelButtonText,
      modalBody,
      location,
    } = this.props;

    if (isInitializing) {
      return null;
    }

    const cssClass = cx(styles.authenticatedPage, className);

    return (
      <main className={cssClass}>
        <Modal
          isVisible={isModalVisible}
          title={modalTitle}
          onOk={this.onClickModalOkButton}
          onCancel={this.onClickModalCancelButton}
          footer={[
            <ButtonContainer key="buttons">
              <PrimaryButton onClick={this.onClickModalOkButton} data-meta-id="modal-ok-button">
                {modalOkButtonText}
              </PrimaryButton>
              <SecondaryButton
                onClick={this.onClickModalCancelButton}
                data-meta-id="modal-cancel-button"
              >
                {modalCancelButtonText}
              </SecondaryButton>
            </ButtonContainer>,
          ]}
        >
          {modalBody}
        </Modal>

        {this.renderHeader()}
        {location.state && location.state.REDIRECT_RESULT ? (
          <div>
            <ShadowBoxOverlay className={styles.error}>
              <AuthenticatedNotification />
            </ShadowBoxOverlay>
          </div>
        ) : (
          this.renderContent()
        )}
      </main>
    );
  }
}

const hyphensOrSlashesRegExp = /(-([a-z])|\/([a-z]))/g;
const CUT_PATH_LENGTH = '/auth/'.length;

function mapStateToProps(state, ownProps) {
  const stateTreeBranchName = ownProps.location.pathname
    .slice(CUT_PATH_LENGTH)
    .replace(hyphensOrSlashesRegExp, (g) => g[1].toUpperCase());

  const formFieldsMap = state[stateTreeBranchName] && state[stateTreeBranchName].form;
  const isPageDirty =
    Boolean(formFieldsMap) &&
    Object.keys(formFieldsMap).some((fieldName) => formFieldsMap[fieldName].dirty);

  return {
    isInitializing: state.me.main.isCheckingAuth || state.me.loadData.isLoadingData,
    isAuthenticated: state.me.main.isAuthenticated,
    isAuthenticatedByNexo: state.me.main.isAuthenticatedByNexo,
    isPageDirty,
    isModalVisible: state.modal.isVisible,
    modalTitle: state.modal.title,
    modalBody: state.modal.body,
    modalOkButtonText: state.modal.okButtonText,
    modalCancelButtonText: state.modal.cancelButtonText,
    modalOkCallback: state.modal.okCallback,
    modalCancelCallback: state.modal.cancelCallback,
    dictionary: state.translation.main.dictionary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeUrl(url) {
      applicationActionCreator.changeUrl(url);
    },
    dispatchResetState() {
      dispatch(meActionCreator.resetState());
    },

    dispatchInitialize(redirectLocation, redirectImmediately) {
      dispatch(meActionCreator.initialize(redirectLocation, redirectImmediately));
    },

    dispatchShowModal(stringifiedModalProps, okCallback, cancelCallback) {
      dispatch(modalActionCreator.show(stringifiedModalProps, okCallback, cancelCallback));
    },

    dispatchHideModal() {
      dispatch(modalActionCreator.hide());
    },
  };
}

export { AuthenticatedLayout as UnwrappedAuthenticatedLayout };
export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayout);
